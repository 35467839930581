import React, { useContext } from "react"

import Layout from "layout"
import FlexmedCashlessCart from "../../Cart"
import EpharmacyFmc from "../../../Epharmacy/EpharmacyFmc"

import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"
import { useFmcProducts } from "./hooks/useFmcProducts"

const FlexmedCashlessOrder = ({ pageContext }) => {
  const data = useFmcProducts()
  let dataMap = data.allAirtableallSitelFmcProducts.nodes.map(
    (node) => node.data
  )
  // transform data before passing to epharmacyFmc
  dataMap = dataMap.map(reformatAirtableData)
  const { flexmedCashlessState, flexmedCashlessDispatch } = useContext(
    FlexmedCashlessContext
  )

  return (
    <Layout {...pageContext?.module} currentStep={2} totalSteps={4}>
      <EpharmacyFmc
        pageContext={pageContext}
        cartContainer={
          <FlexmedCashlessCart
            open={{ documentsCard: false, medicinesCard: true }}
            flexmedCashlessState={flexmedCashlessState}
            flexmedCashlessDispatch={flexmedCashlessDispatch}
            pageContext={pageContext}
          />
        }
        data={dataMap}
        lookupKeys={["brand", "molecule"]}
        flexmedCashlessState={flexmedCashlessState}
        flexmedCashlessDispatch={flexmedCashlessDispatch}
      />
    </Layout>
  )
}

// change data format from Airtable to fit fuzzySearch
const reformatAirtableData = (dataMapElement) => {
  let jsonFormat = {
    brand: dataMapElement?.brand,
    molecule: dataMapElement?.molecule,
    price_list: {
      name: dataMapElement?.priceList,
      vat: dataMapElement?.vat,
      vatex_unit_price: dataMapElement?.vatexUnitPrice,
    },
    productTitle: dataMapElement.productTitle?.[0],
    rxRequired: dataMapElement.rxRequired?.[0],
    DrugName: dataMapElement?.DrugName?.[0],
    skuTags: dataMapElement?.skuTags,
    form: dataMapElement?.form,
    strength: dataMapElement?.strength,
  }

  return jsonFormat
}

export default FlexmedCashlessOrder
